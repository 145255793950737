import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { Checkbox } from '@atoms';

import CardCenter from '@components/Card/CardCenter';

import GoogleTagManagement from '@GoogleTagManagement';

import { getQueryParameters } from '@helpers';

import CongratImage from '@img/congrat.svg';
import { ReactComponent as Cloche } from '@img/icons/cloche.svg';
import { ReactComponent as Email } from '@img/icons/email.svg';
import { ReactComponent as MDP } from '@img/icons/mot_de_passe.svg';

import './confirm-email.scss';

export default function Congrat() {
  const [congrat, setCongrat] = useState(false);
  const [check, setCheck] = useState(false);
  const [confirmEmailData, setConfirmEmailData] = useState('');

  const location = useLocation();

  useEffect(() => {
    setConfirmEmailData(
      getQueryParameters(decodeURIComponent(location.search))
    );
  }, [location]);

  const onSubmit = (e) => {
    e.preventDefault();
    check &&
      axios
        .post(
          `${process.env.REACT_APP_BACK_URL}/api/edenred/identity/${confirmEmailData.username}/verify-email?cgu=true`,
          { verification_code: confirmEmailData.token }
        )
        .then((res) => {
          setCongrat(true);
        })
        .catch((error) => {});
  };

  return (
    <CardCenter
      data-cy="Card-confirmEmail"
      col_class="create-account min-height-full-with-navbar flex-full-centered"
    >
      <GoogleTagManagement titlePage="Compté créé" />
      {congrat ? (
        <div className="congrat">
          <div className="card-body text-center">
            <h4 className="card-title fw-bold">Félicitations !</h4>
            <img
              src={CongratImage}
              className="img-fluid"
              alt="felicitation"
            ></img>
            <p className="card-text fw-bold success">
              Votre compte a été créé avec succès !
            </p>
            <p className="card-text">
              Vous pouvez dès maintenant accéder à votre espace client.
            </p>
            <Link
              to={{ pathname: '/login' }}
              className="btn btn-primary btn-block rounded-pill"
              data-cy="access-client-space"
            >
              Accéder à mon espace client
            </Link>
          </div>
        </div>
      ) : (
        <>
          <GoogleTagManagement titlePage="Validation de la création de compte" />
          <div className="card-header">
            <div className="steps">
              <div className={'step active'}>
                <Email className="stroke-primary" />
              </div>
              <div className={'separator active'}></div>
              <div className={'step active'}>
                <MDP className="stroke-primary" />
              </div>
              <div className={'separator active'}></div>
              <div className={'step active'}>
                <Cloche className="stroke-primary" />
              </div>
            </div>
          </div>
          <div className="card-body w-90">
            <div className="card-conditions-generales">
              <h4 className="card-title fw-bold">
                Dernière étape pour créer votre compte !
              </h4>
              <form action="" autoComplete="off" onSubmit={(e) => onSubmit(e)}>
                <div className="form-group">
                  <Checkbox
                    value={check}
                    onChange={() => setCheck(!check)}
                    required={true}
                    id="conditionCheck"
                    name={'conditionCheck'}
                    label={
                      <span>
                        J'accepte{' '}
                        <u>
                          <Link
                            to="documents/Def_CGU_Espace_Client_ERF_2024.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            download
                          >
                            les conditions générales d'utilisation
                          </Link>
                        </u>
                      </span>
                    }
                  />
                </div>
                <button
                  type="submit"
                  id="sendlogin"
                  className={`btn btn-primary btn-block rounded-pill ${
                    check === false ? 'disabled' : ''
                  }`}
                  disabled={check === false ? 'disabled' : ''}
                >
                  Je crée mon compte
                </button>
              </form>
            </div>
          </div>
        </>
      )}
    </CardCenter>
  );
}
