import React, { useEffect, useState } from 'react';

import axios from 'axios';

import CardCenter from '@components/Card/CardCenter';
import LoadingButton from '@components/LoadingButton/LoadingButton';

import GoogleTagManagement from '@GoogleTagManagement';

import ForbiddenMailImage from '@img/forbidden_mail.svg';

import './UserNotVerified.scss';

export default function UserNotVerified({ email, setEmail, setStep }) {
  const [loading, setLoading] = useState(false);

  const sendMail = () => {
    setLoading(true);

    axios
      .get(
        `${process.env.REACT_APP_BACK_URL}/api/edenred/identity/${email}/${email}/resend-email-verification`
      )
      .then((res) => {})
      .catch((error) => {})
      .then(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    sendMail();
  }, []);

  return (
    <CardCenter row_class="min-height-full-with-navbar" id="user-not-verified">
      <GoogleTagManagement titlePage="Difficultés à me connecter : Validation de l'adresse e-mail" />
      <div className="card-body text-center">
        <h2 className="card-title fw-bold text-center text-break">
          Validation de l'adresse e-mail
        </h2>
        <img
          src={ForbiddenMailImage}
          className="img-fluid"
          alt="mot de passe oublie"
        ></img>
        <p className="card-text mt-3">
          Pour finaliser la dernière étape de création de votre compte, vous
          allez recevoir un e-mail à l’adresse :
        </p>
        <p className="card-text fw-bold">{email}</p>
        <p className="card-text">
          Cliquez sur le lien de l'e-mail pour valider votre adresse. Si vous
          n'avez rien reçu, pensez à regarder dans votre spam
        </p>
        <p className="card-text">
          Si après avoir vérifier vos spams vous ne trouvez pas le mail
          d'activation, vous pouvez modifier votre adresse ou réessayer
          d'envoyer l'email.{' '}
        </p>
      </div>

      <div className="d-flex flex-md-row mt-3 mb-md-3 flex-column gap-3">
        <LoadingButton
          text="Renvoyer l'e-mail"
          loading={loading}
          type="button"
          id="submitMail"
          classes="btn btn-block btn-white-border rounded-pill ms-lg-1 fw-bold"
          spinnerHeight={18}
          onClick={sendMail}
        />

        <LoadingButton
          text="Modifier l'email"
          type="button"
          id="submitMail"
          classes="btn btn-block rounded-pill me-md-1 btn-primary r mt-3 mt-md-0 fw-bold"
          spinnerHeight={18}
          onClick={() => {
            setEmail('');
            setStep(0);
          }}
        />
      </div>
    </CardCenter>
  );
}
