const config = (data) => {
  if (!data) return null;

  const { type, amount, discountedAmount } = data;

  const configs = {
    cancel: {
      title: 'Vous avez annulé votre paiement ',
      text: 'Votre porte-monnaie chèques cadeaux n’a pas été crédité.',
    },
    error: {
      title: 'Votre paiement a échoué',
      text: "Suite à une erreur technique, votre paiement n'a pas pu être pris en compte. Le montant n'a pas été débité de votre compte bancaire.</br> Veuillez réessayer ultérieurement.",
    },
    pending: {
      title: 'Votre paiement est en cours de traitement',
      text: `Nous sommes en attente des informations de votre banque afin de créditer le montant de ${amount}€ sur votre porte-monnaie.</br> Vous recevrez un email de confirmation quand le paiement sera validé.`,
    },
    pending_skynet: {
      title: "Votre paiement est en cours d'enregistrement",
      text: `Votre paiement d'un montant de <strong>${amount} €</strong> a bien été enregistré et votre porte-monnaie sera crédité dans les plus brefs délais. </br> Si le montant n'est toujours pas crédité <strong>sous 48h</strong>, n'hésitez pas à vous rapprocher de votre chargé de clientèle.`,
      type: 'pending',
    },
    success: {
      title: 'Votre paiement a été accepté',
      text: discountedAmount
        ? `Votre porte-monnaie chèque cadeau a été crédité de ${amount}€ grâce à votre paiement de ${discountedAmount}€. </br> Le document correspondant est disponible dans le menu “Mes documents”.`
        : `Votre porte-monnaie chèque cadeau a été crédité grâce à votre paiement de ${amount}€. </br> Le document correspondant est disponible dans le menu “Mes documents”.`,
    },
    refuse: {
      title: 'Votre paiement a été refusé',
      text: 'Merci de réessayer ultérieurement ou contacter votre établissement bancaire si le problème persiste.',
    },
  };

  return configs[type];
};

export default config;
